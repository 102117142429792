<template>
  <div class="plans">
    <div class="plans-title">Our plans are created by world class experts</div>
    <div class="plans-box">
      <div class="plans-box__item">
        <div class="plans-box__item-avatar">
          <img src="@/assets/img/paywall/plans1.jpg" alt="" />
        </div>
        <div class="plans-box__item-name">Dr. Helen Carter</div>
        <div class="plans-box__item-position">
          Lead Behavioral Neuroscience Expert, PhD
        </div>
        <div class="plans-box__item-text">
          "Rewiring the mind redefines wellness. Practice amplifies lasting
          transformation."
        </div>
      </div>
      <div class="plans-box__item">
        <div class="plans-box__item-avatar">
          <img src="@/assets/img/paywall/plans2.jpg" alt="" />
        </div>
        <div class="plans-box__item-name">Dr. Michael Schwartz</div>
        <div class="plans-box__item-position">
          Lead Clinical Hypnotherapy Expert, CCH
        </div>
        <div class="plans-box__item-text">
          "Insight paves the path to inner mastery. Consistency is the key to
          unlocking enduring change."
        </div>
      </div>
      <div class="plans-box__item">
        <div class="plans-box__item-avatar">
          <img src="@/assets/img/paywall/plans3.jpg" alt="" />
        </div>
        <div class="plans-box__item-name">Ananya Saraswati</div>
        <div class="plans-box__item-position">Certified Yoga Instructor</div>
        <div class="plans-box__item-text">
          "Flow, Breathe And Connect With Every Moment!"
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plans {
  margin-bottom: 104px;
  color: #fff;
  &-title {
    max-width: 647px;
    width: 100%;
    margin: 0 auto 72px;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    font-weight: 700;
  }

  &-box {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;

    &__item {
      max-width: 320px;
      width: 100%;
      text-align: center;

      &-avatar {
        width: 144px;
        height: 144px;
        display: flex;
        border-radius: 50%;
        margin: 0 auto 32px;
        overflow: hidden;
      }

      &-name {
        font-weight: 600;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &-position {
        font-weight: 400;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 16px;
      }

      &-text {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        font-weight: 700;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 56px;
    &-title {
      font-size: 24px;
      max-width: 333px;
      margin: 0 auto 32px;
    }

    &-box {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 56px;

      &__item {
        &-avatar {
          width: 104px;
          height: 104px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
