<template>
  <div class="paywall-banner">
    <div class="paywall-banner__text">
      <div class="paywall-banner__text-title" v-html="title"></div>
      <p class="paywall-banner__text-desc" v-html="desc"></p>
      <div class="paywall-banner__text-img" v-if="isTablet">
        <img src="@/assets/img/paywall/banner.png" alt="" />
      </div>
      <button class="paywall-button" @click="$emit('clickButton')">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        {{ btnText }}
      </button>
    </div>
    <div class="paywall-banner__img" v-if="!isTablet">
      <img src="@/assets/img/paywall/banner.png" alt="" />
    </div>
  </div>
</template>

<script setup>
import useWindowResize from '@/utils/useWindowResize'

const { isTablet } = useWindowResize()
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  desc: {
    type: String,
    default:
      'Empower Your Mind: Unlock Serenity with Revolutionary CBT-based Hypnotherapy On-the-Go!',
  },
  btnText: {
    type: String,
    default: 'Get My Plan',
  },
})
</script>

<style lang="scss" scoped>
.paywall {
  &-banner {
    display: flex;
    align-items: center;
    position: relative;
    color: #fff;
    margin-bottom: 54px;

    &__text {
      &-title {
        font-weight: 700;
        font-size: 56px;
        line-height: 72px;
        margin-bottom: 24px;
      }
      &-desc {
        font-weight: 400;
        font-size: 28px;
        margin-bottom: 56px;
        max-width: 440px;
      }
    }

    &__img {
      max-width: 840px;
      width: 100%;
      top: 0;
      position: relative;
      right: 0;
    }

    @media (max-width: 991px) {
      height: auto;
      justify-content: center;
      &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-title {
          max-width: 281px;
          font-size: 28px;
          margin-bottom: 16px;
          line-height: normal;
        }
        &-desc {
          max-width: 238px;
          width: 100%;
          font-size: 14px;
          margin: 0 auto 32px;
        }

        &-img {
          max-width: 320px;
          width: 100%;
          margin-bottom: 32px;
        }
      }
    }

    @media (max-width: 420px) {
      &__text {
        width: 100%;

        & button {
          max-width: 100%;
        }

        &-img {
          max-width: inherit;
          width: 100vw;
          right: 17px;
          position: relative;
        }
      }
    }
  }
}
</style>
