<template>
  <div class="paywall">
    <div class="paywall-bg"></div>
    <div class="container container--vsl">
      <div class="paywall-header">
        <router-link to="/">
          <img src="@/assets/icons/logo.svg" />
        </router-link>
      </div>
      <div class="paywall-body">
        <paywall-banner
          :title="quizResult.headline"
          desc="Empower Your Mind: Unlock Serenity with Revolutionary CBT-based
        Hypnotherapy On-the-Go!"
          @clickButton="$emit('scroll')"
        />

        <paywall-timer @clickButton="$emit('scroll')" />

        <paywall-charts :charts="quizResult.charts" />

        <paywall-info @clickButton="$emit('scroll')" />

        <paywall-advantages
          v-if="quizResult.advantages"
          :advantages="quizResult.advantages"
        />

        <paywall-benefits @clickButton="$emit('scroll')" />

        <paywall-reviews
          :testimonials="
            quizResult.testimonials
              ? quizResult.testimonials
              : statickTestimonials
          "
        />

        <div class="users">
          <div class="users-img">
            <img src="@/assets/img/paywall/users.png" alt="" />
          </div>
          <button class="paywall-button users-button" @click="$emit('scroll')">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            Get My Plan
          </button>
        </div>

        <paywall-cycle />

        <paywall-plans />

        <paywall-faq @clickButton="$emit('scroll')" />

        <!-- <paywall-bonuses @clickButton="$emit('scroll')" /> -->

        <paywall-lists />

        <PurchaseDynamic
          :packages="quizResult.subscription_package"
          :noCloseBtn="!quizResult.is_close_button"
          :items="quizResult.package_usp"
          @onClose="onClose"
          :initialOpen="initialOpen"
          :initialPackage="initialPackage"
          :redirect="redirect"
        />

        <paywall-guarantee />

        <!-- <paywall-ask :peopleAsk="peopleAsk" /> -->

        <paywall-footer />
      </div>
    </div>
  </div>
</template>

<script setup>
import PaywallBanner from './PaywallBanner.vue'
import PaywallTimer from './PaywallTimer.vue'
import PaywallCharts from './PaywallCharts.vue'
import PaywallInfo from './PaywallInfo.vue'
import PaywallAdvantages from './PaywallAdvantages.vue'
import PaywallBenefits from './PaywallBenefits.vue'
import PaywallReviews from './PaywallReviews.vue'
import PaywallPlans from './PaywallPlans.vue'
import PaywallFaq from './PaywallFaq.vue'
// import PaywallBonuses from './PaywallBonuses.vue'
import PaywallLists from './PaywallLists.vue'
import PaywallCycle from './PaywallCycle.vue'
// import PaywallAsk from './PaywallAsk.vue'
import PaywallGuarantee from './PaywallGuarantee.vue'
import PaywallFooter from './PaywallFooter.vue'
import PurchaseDynamic from '@/components/subscribe/PurchaseDynamic.vue'
import { statickTestimonials } from '@/data/paywallData.js'
import { useRoute, useRouter } from 'vue-router'
import { peopleAsk } from '@/data/paywallData'
import { setQuiz, getSingleQuiz } from '@/services/quiz.service'
import { onMounted } from 'vue'

const router = useRouter()
const route = useRoute()
const props = defineProps([
  'quizResult',
  'initialOpen',
  'initialPackage',
  'redirect',
])

if (route.name !== 'QuizResults') {
  let statistic_id
  ;(async () => {
    console.log(route)
    const id = route.params.slug
    console.log(route.params.slug)
    const response = await getSingleQuiz(id)

    statistic_id = response.statistic_id

    const isJustRegistered = localStorage.getItem('justRegistered')
    const isBounced = localStorage.getItem('isBounced')
    localStorage.setItem('isCountStarted', 1)

    if (isJustRegistered && statistic_id && !isBounced) {
      localStorage.setItem('isBounced', 1)
      setQuiz({ statistic_id: statistic_id, action: 'paywall_visitors' })
    }
  })()

  const checkLocalStorage = () => {
    const isCountStarted = localStorage.getItem('isCountStarted')

    if (isCountStarted) {
      sendStatistic()
    }
  }

  const sendStatistic = async () => {
    await setQuiz({
      statistic_id: statistic_id,
      action: 'paywall_bounced',
    })
    localStorage.removeItem('isCountStarted')
  }

  onMounted(() => {
    setTimeout(checkLocalStorage, 5 * 60 * 1000)
  })
}

const onClose = () => {
  router.push('/home')
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
.paywall {
  min-height: 100vh;

  &-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url('../../../assets/img/paywall/stars.svg'),
      linear-gradient(293deg, #71b280 0%, #134e5e 100%);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &-header {
    margin-bottom: 32px;
    padding-top: 36px;
  }

  @media (max-width: 767px) {
    &-header {
      & a {
        display: flex;
        justify-content: center;
        max-width: 144px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.users {
  margin-bottom: 104px;

  &-img {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 32px;
  }

  &-button {
    display: flex;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
}
</style>
