<template>
  <div class="quiz-results__video">
    <video
      class="quiz-results__video-tag"
      width="327"
      height="184"
      ref="quizResultVideo"
      :controls="isHidePlayBtn"
      autoplay
      loop
      muted
      playsinline
    >
      <source
        :src="
          dynamicVideoUrl ? `${dynamicVideoUrl}#t=0.1` : `${videoUrl}#t=0.1`
        "
        type="video/mp4"
      />
    </video>
    <button
      v-if="!isHidePlayBtn"
      class="quiz-results__video__play btn-reset"
      @click="onPlay"
    >
      <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M32.5 65C50.45 65 65 50.45 65 32.5S50.45 0 32.5 0 0 14.55 0 32.5 14.55 65 32.5 65z" fill="#fff"/><path d="M44.667 30.788a1.999 1.999 0 010 3.424L26.534 45.167a2 2 0 01-3.034-1.712v-21.91a2 2 0 013.034-1.712l18.133 10.955z" fill="#fff"/></svg>
    </button>
  </div>
</template>

<script>
import { videoUrl } from '@/utils/helpers'
export default {
  name: 'PaywallVideo',
  props: ['dynamicVideoUrl'],
  data() {
    return {
      isHidePlayBtn: false,
      videoUrl,
    }
  },
  mounted() {
    this.onPlay()
    this.$refs.quizResultVideo.onpause = () => {
      this.isHidePlayBtn = false
    }
    this.$refs.quizResultVideo.onplay = () => {
      this.isHidePlayBtn = true
    }
  },
  methods: {
    onPlay() {
      this.$refs.quizResultVideo.play()
    },
  },
}
</script>

<style></style>
